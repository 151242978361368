<template>
  <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" :max-size="5 * 1024 * 1024"
    :before-read="beforeRead" preview-size="100px" @oversize="onOversize" @delete="onDelete" />
</template>

<script>
import { ref } from 'vue'
import { Uploader } from 'vant';
import Axios from "axios";

export default {
  components: {
    [Uploader.name]: Uploader,
  },
  setup() {
    return {
      fileList: ref([]),
      img: ref(""),
      us: ref({})
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
  },
  methods: {
    onOversize(file) {
      // 图片大小校验
      this.$q.dialog({
        message: "请上传5M内的图片",
      });
    },
    beforeRead(file) {
      // 图片格式校验
      if (
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/gif"
      ) {
        return true;
      } else {
        this.$q.dialog({
          message: "请上传 jpg 格式图片",
        });
        return false;
      }
    },
    afterRead(file) {
      let _this = this;
      let _formdata = new FormData(); //创建form对象
      _formdata.append("file", file.file);

      let _posturl =
        _this.$config.send_url +
        "Api/Upload/UploadImages?userid=" +
        _this.us.userid + "&token=" + encodeURIComponent(_this.$utils.getloc("token"));
      let _config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      Axios.post(_posturl, _formdata, _config)
        .then((res) => {
          if (res.data.code == 100) {
            let data = res.data.data.imgname;
            _this.img = data
            _this.$emit('success', _this.img)
          } else {
            this.$q.dialog({
              message: res.data.msg,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onDelete() {
      this.img = ""
      this.$emit('onDelete')
    },
  }
}
</script>

<style scoped></style>