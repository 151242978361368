<template>
  <div class="apply">
    <ShopNavBar title="联盟商家申请" />

    <div class="form">
      <van-field v-model="formData.levelName" label="申请级别" placeholder="请选择级别" readonly>
        <template #input>
          <van-radio-group v-model="formData.shopType" direction="horizontal">
            <van-radio :name="item.number" v-for="item in levelList" :key="item.number" checked-color="#f8124b">
              {{ item.name }}
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field v-model="formData.shopName" label="店铺名称" placeholder="请输入店铺名称" />
      <van-field v-model="formData.showArea" label="地区" placeholder="请选择地区" readonly @click="show = true" />
      <van-field v-model="formData.address" label="详细地址" placeholder="请输入详细地址" />
      <van-field v-model="formData.shopFrdh" label="联系电话" placeholder="请输入联系电话" />
      <div class="cell">
        <div class="cell-label">门店照片</div>
        <UploadImage @success="e => formData.shopImg = e" @onDelete="formData.shopImg = ''" />
      </div>
      <div class="cell">
        <div class="cell-label">营业执照</div>
        <UploadImage @success="e => formData.shopYyzz = e" @onDelete="formData.shopYyzz = ''" />
      </div>
      <van-field v-model="formData.bz" label="备注" placeholder="可不填" />
    </div>

    <div class="btn" @click="apply">申请</div>
    <div class="record">
      <span @click="tolink('applyRecoed')">申请记录</span>
    </div>

    <!-- 地址 -->
    <van-popup v-model:show="show" position="bottom">
      <van-area :value="formData.areaCode" title="选择地址" confirm-button-text="确定" cancel-button-text="取消" :area-list="areaList"
        @confirm="confirm" @cancel="show = false" />
    </van-popup>
  </div>
</template>

<script>
import { Field, Popup, Picker, Area, Toast, RadioGroup, Radio } from 'vant';
import { ref } from 'vue';

import areaList from "@/assets/js/area.js";
import UploadImage from '@/components/UploadImage'

export default {
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Area.name]: Area,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Toast.name]: Toast,
    UploadImage
  },
  setup() {
    return {
      formData: ref({
        shopType: 0,
        levelName: "",
        shopName: "",
        address: "",
        sheng: "",
        shi: "",
        xian: "",
        areaCode: "",
        showArea: "",
        bz: "",
        shopImg: "",
        shopFrdh: "",
        shopYyzz: ""
      }),
      us: ref(null),
      fileList: ref([]),
      levelList: ref([]),
      show: ref(false),
      areaList
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.geApplyList()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    confirm(e) {
      this.formData.sheng = e[0].name,
      this.formData.shi = e[1].name
      this.formData.xian = e[2].name
      this.formData.areaCode = e[2].code
      this.formData.showArea = e[0].name + e[1].name + e[2].name
      this.show = false
    },
    // onOversize(file) {
    //   // 图片大小校验
    //   this.$q.dialog({
    //     message: "请上传5M内的图片",
    //   });
    // },
    // beforeRead(file) {
    //   // 图片格式校验
    //   if (
    //     file.type == "image/jpeg" ||
    //     file.type == "image/png" ||
    //     file.type == "image/gif"
    //   ) {
    //     return true;
    //   } else {
    //     this.$q.dialog({
    //       message: "请上传 jpg 格式图片",
    //     });
    //     return false;
    //   }
    // },
    // afterRead(file) {
    //   let _this = this;
    //   let _formdata = new FormData(); //创建form对象
    //   _formdata.append("file", file.file);

    //   let _posturl =
    //     _this.$config.send_url +
    //     "Api/Upload/UploadImages?userid=" +
    //     _this.us.userid + "&token=" + encodeURIComponent(_this.$utils.getloc("token"));
    //   let _config = {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   };
    //   Axios.post(_posturl, _formdata, _config)
    //     .then((res) => {
    //       if (res.data.code == 100) {
    //         let data = res.data.data.imgname;
    //         _this.formData.shopImg = data
    //       } else {
    //         this.$q.dialog({
    //           message: res.data.msg,
    //         });
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    // onDelete() {
    //   this.formData.shopImg = ""
    // },
    geApplyList() {
      let _this = this
      _this.$request.post(
        "api/UsersShopApply/UserShopTypeList",
        {},
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data = data.filter(item => item.display)
            _this.levelList = data
          }
        }
      )
    },
    apply() {
      let _this = this
      if(this.formData.shopName == '') {
        Toast('请输入店铺名称')
        return
      }
      if(this.formData.areaCode == '') {
        Toast('请选择地区')
        return
      }
      if(this.formData.address == '') {
        Toast('请输入详细地址')
        return
      }
      if(this.formData.shopFrdh == '') {
        Toast('请输入联系电话')
        return
      }
      if(this.formData.shopImg == '') {
        Toast('请上传门店照片')
        return
      }
      if(this.formData.shopYyzz == '') {
        Toast('请上传营业执照')
        return
      }
      this.$q.dialog({
        message: "确定要申请吗？",
      }).onOk(() => {
        _this.$request.post(
          "api/UsersShopApply/Apply",
          {
            
            userid: _this.us.userid,
            shopLevel: 1,
            shopName: _this.formData.shopName,
            shopSheng: _this.formData.sheng,
            shopShi: _this.formData.shi,
            shopXian: _this.formData.xian,
            shopAddress: _this.formData.address,
            shopAddressCode: _this.formData.areaCode,
            bz: _this.formData.bz,
            shopImg: _this.formData.shopImg,
            shopYyzz: _this.formData.shopYyzz,
            shopFrdh: _this.formData.shopFrdh,
            shopType: _this.formData.shopType
          },
          (res) => {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.apply {
  padding: 10px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.form {
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
}

.btn {
  margin: 20px 0 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 46px;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.record {
  margin-top: 12px;
  font-size: 13px;
  color: gray;
  text-align: right;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;
}

.cell::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  border-bottom: 1px solid #ebedf0;
  transform: scaleY(.5);
}

.cell-label {
  margin-right: 12px;
  width: 86px;
  color: #646566;
}
</style>